import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiConstants } from '../../constants/api.constant';
import { RoutesContants } from '../../constants/routes.constant';
import { DataService } from '../../services/data.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isLogin: boolean = false;
  userType: any;
  userDetails:any;
  ImageAPI = environment.ServiceUrl + ApiConstants.routes.getImage;
  formRoutes = RoutesContants.formRoutes;
  categories = [];

  selectedCategoryId: any;

  constructor(
    private _us: UtilService,
    private _ds: DataService,
    private _router: Router,
    private _ar: ActivatedRoute
  ) {
    this.isLogin = this._us.isUserLogin();
    this.userType = this._us.getUserType();
    if(this.isLogin) {
      this.userDetails = this._us.getUserDetails();
    }

    this._us.notifyObs$.subscribe(x => {
      if (x?.profileUpdated) {
        this.userDetails = this._us.getUserDetails();
      }
    })
  }


  ngOnInit() {
    if (this.userType != '2') {
      this._ds.getCategories().subscribe(x => {
        this.categories = x?.data ?? [];
      })
    }
  }

  onCategorySelect(id) {
    this.selectedCategoryId = id;
    this._us.bindCategoryToRoute(this.selectedCategoryId);
    // this._us.notifyOther({ 'findByCatId': this.selectedCategoryId });
    // if (this._router.url.includes('/find-jobs')) {
    //   this._router.navigate([], {
    //     relativeTo: this._ar,
    //     queryParams: { category: this.selectedCategoryId },
    //     queryParamsHandling: 'merge',
    //     replaceUrl: true
    //   })
    // } else {
    //   this._router.navigate([RoutesContants.formRoutes.findJobs], { queryParams: { category: this.selectedCategoryId } });
    // }
  }

  logout() {
    // Clear local storage or session data
    localStorage.clear();
    this.isLogin = false;
    this._router.navigate([RoutesContants.formRoutes.signin])  
  }
}
